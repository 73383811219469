<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-top">
				<div class="item-wrap">
					<div class="item">{{$t('teacher.score.ranking')+':'}} {{memberInfo.total_score_ranking}}</div>
					<div class="item">
						<span class="item-active">{{$t('teacher.score.systemRank')+':'}}{{memberInfo.intelligent_score_ranking}}</span>
					</div>
					<div class="item">
						<router-link class="item-link" :to="{name:'teacherScoreManage',params: {id: $route.params.id}}">{{$t('teacher.score.teacherRank')+':'}} {{memberInfo.teacher_rating_ranking}}</router-link>
					</div>
				</div>
				<div class="item-wrap">
					<div class="item">{{$t('teacher.score.studentName')+':'}} {{memberInfo.real_name}}</div>
					<div class="item">{{$t('teacher.score.account')+':'}} {{memberInfo.user_name}}</div>
					<div class="item">{{$t('teacher.score.shopName')+':'}} {{memberInfo.store_name}}</div>
				</div>
				<div class="score-table">
					<table width="100%">
						<thead>
							<tr>
								<th width="12%">{{$t('teacher.tableHead.total')}}</th>
								<th width="12%">{{$t('teacher.tableHead.totalPoints')}}</th>
								<th width="12%">{{$t('teacher.tableHead.intelligentScore')}}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td></td>
								<td align="center">79</td>
								<td align="center">{{memberInfo.system_score?memberInfo.system_score:0}}</td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="t-page-main">
				<div class="item" v-for="(score,index) in scoreItems" :key="index">
					<div class="item-head">{{score.name}}</div>
					<div class="score-table">
						<table width="100%">
							<thead>
								<tr>
									<th width="12%">{{$t('teacher.tableHead.title')}}</th>
									<th width="12%">{{$t('teacher.tableHead.fraction')}}</th>
									<th width="12%">{{$t('teacher.tableHead.analyticScore')}}</th>
									<th width="12%">{{$t('teacher.tableHead.intelligentScore')}}</th>
									<th>{{$t('teacher.tableHead.describe')}}</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(item,index2) in score.lists">
									<tr :key="index2">
										<td :rowspan="item.score_config_item.length+1">{{item.name}}</td>
										<td :rowspan="item.score_config_item.length+1" align="center">{{item.ini_score}}</td>
									</tr>
									<tr v-for="(child,index3) in item.score_config_item" :key="index2+'_'+index3">
										<td align="center">{{child.score_a}}</td>
										<td align="center">{{child.score_b}}</td>
										<td>{{child.remark}}</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
				
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { fetchSystemScore } from '@/api/teacher';
	export default {
		data() {
			return {
				memberInfo: {},
				scoreItems: {},
			}
		},
		created() {
			this.getScoreInfo();
		},
		methods: {
			getScoreInfo(){
				fetchSystemScore({member_id: this.$route.params.id}).then(response => {
					this.memberInfo = response.data.member_info;
					this.scoreItems = response.data.score_items;
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 20px;
		.score-table {
			table {
				border-collapse: collapse;
				th,td {
					padding: 10px;
					line-height: 20px;
					border: 1px solid $--seller-thead-background-1;
				}
			}
		}
		.t-page-top {
			margin-bottom: 20px;
			.item-wrap {
				margin-bottom: 10px;
				.item {
					width: 200px;
					display: inline-block;
					line-height: 20px;
				}
				.item-link {
					color: #f56c6c;
				}
				.item-link:hover {
					text-decoration: underline;
				}
				.item-active {
					color: $--seller-primary;
				}
			}
			.score-table {
				th {
					background-color: $--seller-thead-background-2;
				}
			}
		}
		.t-page-main {
			.item {
				margin-bottom: 20px;
				.item-head {
					font-size: 16px;
					line-height: 24px;
					padding: 10px;
					font-weight: bold;
					background-color: $--seller-thead-background-2;
				}
			}
			.score-table {
				th,td {
					border: 1px solid $--seller-thead-background-2;
				}
			}
		}
	}
</style>
